import React from "react"

import {
  useShopifyVariants,
  getVariantBySelectedOptions,
} from "../../../hooks/useShopify"
import { useApp } from "../../../hooks/useApp"

export const withWishlistItem = Component => ({
  name = "WishlistItem",
  item,
  handleDelete,
}) => {
  const { activeVariant, selectedOptions, handleVariant } = useShopifyVariants({
    product: item,
    preSelectedVariantTitle: item?.selectedTitle,
  })

  const {
    config: {
      settings: {
        constraints: { SIZE },
        routes: { PRODUCT },
      },
    },
  } = useApp()

  const { images, handle, title, options } = item

  const url = `${PRODUCT}/${handle}`

  const defaultVariant = item.variants?.[0]

  const isVariantOutOfStock = option => {
    const variantSelectedOptions = [
      ...selectedOptions.filter(
        selectedOption => selectedOption.name !== option.name,
      ),
      option,
    ]
    const variant = getVariantBySelectedOptions(
      variantSelectedOptions,
      item?.variants,
    )

    return !variant?.availableForSale
  }

  Component.displayName = name
  return (
    <Component
      product={item}
      handle={handle}
      url={url}
      title={title}
      images={images}
      activeVariant={activeVariant}
      defaultVariant={defaultVariant}
      options={options}
      selectedOptions={selectedOptions}
      handleVariant={handleVariant}
      handleDelete={handleDelete}
      SIZE={SIZE}
      isVariantOutOfStock={isVariantOutOfStock}
    />
  )
}
