import tw, { styled } from "twin.macro"

import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw`py-3-2 md:py-8`}
`

export const Divider = styled.hr`
  ${tw`mb-3 md:hidden text-grey-lightest`}
  ${({ noResults }) => (noResults ? tw`md:block` : tw``)}
`

export const Title = tw.h1`
  text-28 leading-1.28 tracking-10 md:text-35 font-bold uppercase text-center mb-2-4
`

export const Count = tw.span`
  w-full block text-center md:text-left text-14 leading-1.71 tracking-10 font-bold uppercase mb-3
`

export const Outer = tw.div`
  flex flex-col md:flex-row justify-between mb-2
`

export const Inner = tw.div`
  flex flex-col
`

export const Mobile = tw.div`
  md:hidden
`

export const Desktop = tw.div`
  hidden md:block
`
