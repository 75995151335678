import React from "react"
import { useWishlistContext } from "../../hooks/useWishlist"

export const withWishlist = Component => ({ name = "Wishlist", page }) => {
  const { title } = page || {}
  const { wishlist } = useWishlistContext()

  Component.displayName = name
  return <Component title={title} count={wishlist?.length ?? 0} />
}
