import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"

export const withWishlistList = Component => ({ name = "WishlistList", ...props }) => {
  const { wishlistEmpty } = useStaticQuery(graphql`
    query SANITY_PAGE_WISHLIST_EMPTY {
      wishlistEmpty: sanityPageAccountWishlist {
        emptyTitle
        emptyLink: _rawEmptyLink(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const { emptyTitle, emptyLink } = wishlistEmpty || {}

  const { linkResolver } = useRoutes()

  const link = linkResolver(emptyLink)

  Component.displayName = name
  return <Component emptyTitle={emptyTitle} emptyLink={link} {...props} />
}
