import React from "react"
import { useMounted } from "../../../hooks/useMounted"

import { useWishlist, useWishlistContext } from "../../../hooks/useWishlist"

export const withWishlistCard = Component => ({ name = "WishlistCard" }) => {
  const { hasMounted } = useMounted()
  const {
    sharedWishlist,
    getSharedWishlist,
    deleteFromWishlist,
    loading,
  } = useWishlist()

  const { wishlist } = useWishlistContext()

  const shared = hasMounted ? location?.search?.includes("id") : false
  if (shared && !sharedWishlist?.length) getSharedWishlist()

  const handleDelete = async handle => await deleteFromWishlist(handle)

  Component.displayName = name

  return (
    <Component
      items={shared ? sharedWishlist : wishlist}
      handleDelete={handleDelete}
      loading={loading}
    />
  )
}
