import React from "react"
import { Link } from "gatsby"

import { WishlistItem } from "../Item/WishlistItem"
import { withWishlistList } from "./withWishlistList"
import { Wrapper, EmptyWrapper, Title } from "./WishlistListStyles"
import { StyledButton } from "../../Styled/Button"

export const WishlistList = withWishlistList(
  ({ emptyTitle, emptyLink, items, handleDelete, loading }): JSX.Element => (
    <>
      {items?.length > 0 ? (
        <Wrapper>
          {items?.map(item => (
            <WishlistItem key={item?.id?.toString()} item={item} handleDelete={handleDelete} />
          ))}
        </Wrapper>
      ) : loading ? (
        <p>Loading...</p>
      ) : (
        <EmptyWrapper>
          <Title>{emptyTitle}</Title>
          {emptyLink?.url ? (
            <StyledButton colour={"black-reverse"} as={Link} to={emptyLink?.url}>
              {emptyLink?.title}
            </StyledButton>
          ) : null}
        </EmptyWrapper>
      )}
    </>
  )
)
