import React from "react"
import { graphql } from "gatsby"

import { Wishlist as Page } from "../components/Wishlist/Wishlist"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageAccountWishlist {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("wishlist_page")
  
  return <Page {...props} {...data} />
}
export default Component
