import React from "react"

import { withWishlist } from "./withWishlist"
import {
  Wrapper,
  Outer,
  Inner,
  Title,
  Count,
  Divider,
  Mobile,
  Desktop,
} from "./WishlistStyles"
import { WishlistCard } from "./Card/WishlistCard"
import { WishlistShare } from "./Share/WishlistShare"

export const Wishlist = withWishlist(
  ({ title, count }): JSX.Element => (
    <Wrapper>
      <Outer>
        <Inner>
          <Title>{title}</Title>
          <Desktop>
            <Count>
              {count} {count === 1 ? `item` : `items`}
            </Count>
          </Desktop>
        </Inner>
        <WishlistShare />
      </Outer>
      <Divider noResults={count === 0} />
      <Mobile>
        <Count>
          {count} {count === 1 ? `item` : `items`}
        </Count>
      </Mobile>
      <WishlistCard />
    </Wrapper>
  ),
)
