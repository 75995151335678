import React from "react"
import { Link } from "gatsby"

import {
  Wrapper,
  ImageWrapper,
  DetailsWrapper,
  Details,
  Variants,
  Title,
} from "./WishlistItemStyles"
import { withWishlistItem } from "./withWishlistItem"
import { ImageWithHover } from "../../ImageWithHover/ImageWithHover"
import { Icon } from "../../Icon/Icon"
import { Price } from "../../Product/Price/Price"
import {
  StyledSelectWrapper,
  StyledSelect,
  StyledSelectOption,
  StyledInputIconWrapper,
} from "../../Styled/Form"
import { AddToCart } from "../../Product/AddToCart/AddToCart"
import { Wishlist } from "../../Product/Wishlist/Wishlist"
import { Message } from "../../Product/Message/Message"

export const WishlistItem = withWishlistItem(
  ({
    product,
    handle,
    url,
    title,
    images,
    activeVariant,
    defaultVariant,
    options,
    selectedOptions,
    handleVariant,
    handleDelete,
    SIZE,
    isVariantOutOfStock,
  }): JSX.Element => (
    <Wrapper>
      <ImageWrapper image={images.length > 0 ? "true" : "false"}>
        <Link to={url} title={title}>
          <ImageWithHover
            image={images?.[0]}
            hoverImage={images?.[1]}
            ratio={"15/19"}
          />
        </Link>
        <button onClick={() => handleDelete(handle)}>
          <Icon name={"cross"} />
        </button>
      </ImageWrapper>
      <DetailsWrapper>
        <Details>
          <Title as={Link} to={url} title={title}>
            {title}
          </Title>
          <Wishlist product={product} />
        </Details>
        <Details>
          <Price variant={activeVariant ?? defaultVariant} layout={"card"} />
        </Details>
        <Message tags={product?.tags} layout={"card"} />
        <Variants>
          {options?.map((option, index) =>
            option?.values.length > 1 || option?.name === SIZE ? (
              <StyledSelectWrapper key={option?.name?.toString() + index}>
                <StyledSelect
                  layout={"cardVariant"}
                  value={
                    selectedOptions?.find(({ name }) => name === option?.name)
                      ?.value
                  }
                  onChange={({ target: { value } }) =>
                    handleVariant({ name: option.name, value })
                  }
                >
                  <StyledSelectOption disabled value={""} selected>
                    {option?.name}
                  </StyledSelectOption>
                  {option?.values?.map((value, index) => (
                    <StyledSelectOption
                      key={value?.toString() + index}
                      value={value}
                      disabled={isVariantOutOfStock({
                        name: option.name,
                        value,
                      })}
                    >
                      {value}
                    </StyledSelectOption>
                  ))}
                </StyledSelect>
                <StyledInputIconWrapper>
                  <Icon
                    name={"chevron"}
                    className={`mb-0-2 mr-0-8`}
                    width={"12"}
                    height={"12"}
                  />
                </StyledInputIconWrapper>
              </StyledSelectWrapper>
            ) : null,
          )}
        </Variants>
        <AddToCart
          selectedVariant={activeVariant}
          product={product}
          layout={"wishlist"}
        />
      </DetailsWrapper>
    </Wrapper>
  ),
)
